.App {
  text-align: center;
  width: fit-content;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.att-image {
  max-height: 600px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: grid;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}
.comming-soon {
  font-size: 20px;
  font-weight: bold;
  color: #61dafb;
}
.text-topic {
  font-size: 50px;
  font-weight: bold;
}

.content-box > p {
  margin-top: 0px;
  margin-bottom: 0px;
  width: fit-content;
  padding-top: 10px;
}
.header-logo {
  float: left;
}
.header-text {
  /* position: relative;
  top:100px; */
  padding-top: 10% !important;
}

@media only screen and (max-width: 600px) {
  .header-text {
    /* position: relative;
    top:100px; */
    padding-top: 0% !important;
  }
}

.logocss {
  /* // width: 100%; */
}

.header-text-font {
  font-weight: bold;
  font-size: 30px;
}

.content-box {
  padding-left: 100px;
  text-align: start;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
